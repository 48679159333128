<template>
  <div>
    <div class="row mb-3">
      <div class="col-sm-6">
        <div class="shop_border_input">
          <select v-model="selectedSpecification" @change.prevent="selectSpecification">
            <option v-for="spec in specifications" :key="spec.key" :value="spec.key">{{ spec.name }}</option>
          </select>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="shop_border_input">
          <div class="numberstyle-qty">
            <div class="qty-btn qty-rem" @click.prevent="minus">-</div>
            <input v-if="qty !== null" v-model="qty" class="numberstyle" type="number" min="1" step="1">
            <div v-else class="numberstyle">{{ messages.no_stock }}</div>
            <div class="qty-btn qty-add" @click.prevent="add">+</div>
          </div>
        </div>
      </div>
    </div>
    <dl class="text-right">
      <dt class="line-through h6 margin_btm_none">NT$ {{ sale_item.origin_price.toLocaleString() }}</dt>
      <dd class="cart_total light_red">NT$ {{ specifications.find(spec => spec.key === selectedSpecification).price.toLocaleString() }}</dd>
    </dl>
    <div class="row">
      <div class="col-sm-6 mt-3">
        <a @click="buyNow" href="#!" :class="['login_submit', 'red_light_bg', 'display_flex', 'justify-content-center', 'align-items-center', 'white', {'disabled': qty === null || qty <= 0}]">{{ messages.buy_now }}</a>
      </div>
      <div class="col-sm-6 mt-3">
        <a href="#!" @click="addToCart" :class="['login_submit', 'red_light_bg', 'display_flex', 'justify-content-center', 'align-items-center', 'white', {'disabled': qty === null || qty <= 0}]">{{ messages.add_to_cart }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert';

export default {
  name: 'AddCartBtn',
  props: ['sale_item', 'specifications', 'messages', 'lang'],
  data() {
    return {
      selectedSpecification: this.specifications.length > 0 ? this.specifications[0].key : null,
      qty: this.specifications[0]?.qty > 0 ? 1 : null,
    }
  },
  computed: {
    cart() {
      return this.$store.state.cart;
    }
  },
  methods: {
    minus() {
      if (this.qty > 1) {
        this.qty -= 1
      } else {
        if (this.specifications[0]?.qty > 0) {
          this.qty = 1;
        } else {
          this.qty = null;
        }
        swal({
          title: this.messages.no_less_than_zero,
          icon: "warning",
          button: this.messages.confirm,
        });
      }
    },
    add() {
      if (this.qty === null) {
        swal({
          title: this.messages.sold_out,
          text: "",
          icon: "warning"
        })
      } else {
        if (this.specifications.find((spec) => spec.key == this.selectedSpecification).max_qty > this.qty) {
          this.qty += 1
        } else {
          swal({
            title: this.messages.more_than_max_qty,
            icon: "warning"
          })
        }
      }
    },
    addToCart() {
      if (this.qty === null || this.qty <= 0) {
        swal({
          title: this.messages.sold_out,
          icon: "warning"
        });
      } else {
        const currentSpecification = this.specifications.find(specification => specification.key === this.selectedSpecification);
        if (this.qty > currentSpecification?.max_qty) {
          swal({
            title: this.messages.more_than_max_qty,
            icon: "warning"
          });
        } else {
          this.$store.dispatch('setCart', {...this.cart?.specification, [this.selectedSpecification]: this.qty})
          swal({
            title: this.messages.added,
            icon: "success"
          });
        }
      }
    },
    buyNow() {
      if (this.qty === null || this.qty <= 0) {
        swal({
          title: this.messages.sold_out,
          icon: "warning"
        });
      } else {
        const currentSpecification = this.specifications.find(specification => specification.key === this.selectedSpecification);
        if (this.qty > currentSpecification?.max_qty) {
          swal({
            title: this.messages.more_than_max_qty,
            icon: "warning"
          });
        } else {
          this.$store.dispatch('setCart', {...this.cart?.specification, [this.selectedSpecification]: this.qty})
          swal({
            title: this.messages.added,
            icon: "success"
          }).then(() => {
            window.location.href = `/${this.lang}/carts/orders`;
          });
        }
      }
    },
    selectSpecification() {
      const currentSpecification = this.specifications.find(specification => specification.key === this.selectedSpecification);
      if (currentSpecification?.qty > 0) {
        // 使用可选链操作符
        this.qty = this.cart?.specification?.[this.selectedSpecification] ?? 1;
      } else {
        this.qty = 0;
      }
    },
  }
}
</script>

<style scoped>
/* 这里可以添加组件特定的样式 */
</style>