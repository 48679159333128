<template>
    <table class="cart_table">
      <thead>
        <tr>
          <th scope="col">{{ translation.image }}</th>
          <th scope="col">{{ translation.name }}</th>
          <th scope="col">{{ translation.specification }}</th>
          <th scope="col">{{ translation.price }}</th>
          <th scope="col">{{ translation.quantity }}</th>
          <th scope="col">{{ translation.subtotal }}</th>
          <th scope="col">{{ translation.remove }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(specification, index) in items.specification" :key="`item${index}`" class="specification_inner">
          <td :data-title="translation.image" class="img-preview">
            <a :href="`/${lang}/sale_items/${specification.sale_item.key}`">
              <img :src="specification.sale_item.image.pic.url" />
            </a>
          </td>
          <td :data-title="translation.name" width="35%">
            <a :href="`/${lang}/sale_items/${specification.sale_item.key}`">
              <dl class="margin_btm_none">
                <dt class="h6 light_red mb-1">{{ specification.sale_item.item_no }}</dt>
                <dd>{{ specification.sale_item.title[`title_${lang}`] }}</dd>
              </dl>
            </a>
          </td>
          <td :data-title="translation.specification" width="25%">
            {{ specification.title[`title_${lang}`] }}
          </td>
          <td nowrap :data-title="translation.price" class="m_hidden">NT$ {{ specification.price }}</td>
          <td :data-title="translation.quantity" width="10%">
            <div class="shop_border_input">
              <div class="numberstyle-qty">
                <div class="qty-btn qty-rem" @click="minus(specification.key)">-</div>
                <input class="numberstyle" type="text" :value="cart.specification && cart.specification[specification.key]" readonly>
                <div class="qty-btn qty-add" @click="add(specification.key)">+</div>
              </div>
            </div>
          </td>
          <td :data-title="translation.subtotal" nowrap>NT$ {{cart.specification && (specification.price * cart.specification[specification.key]).toLocaleString()}}
          </td>
          <td :data-title="translation.remove">
            <a class="delete_confirm" href="#!" @click.prevent="remove(specification.key)">
              <i class="icon ion-md-close"></i>
            </a>
          </td>
        </tr>
        <!--- coupon --->
        <tr class="specification_inner">
          <td :data-title="translation.coupon" colspan="4">
            <div>
              {{ translation.coupon }}
              <span v-if="couponDiscount > 0" class="red">{{`(${translation.coupon_success})` }}</span>
              <span v-if="couponDiscount === 0 && couponMessage.length > 0" class="red">{{ `(${couponMessage})` }}</span>
            </div>
          </td>
          <td class="ph-0">
            <div class="col-sm-12 dicount_box display_flex py-2">
              <div class="shop_border_input dicount_box">
                <input
                  type="text"
                  :placeholder="translation.coupon_placeholder"
                  :value="couponNo"
                  @change.prevent="checkCoupon"
                  @keyup.prevent="checkCoupon"
                >
              </div>
            </div>
          </td>
          <!-- 折扣金額 -->
          <td colspan="2">
            <span v-if="couponDiscount > 0">{{ `NT$ ${couponDiscount}` }}</span>
          </td>
        </tr>
        <!-- total amount -->
         <tr class="specification_inner h76">
          <td :data-title="translation.cart_total" colspan="5">
            <div>{{ translation.cart_total }}: </div>
          </td>
          <td nowrap>
            NT$ {{ calculateTotal && calculateTotal.toLocaleString() }}
          </td>
          <td></td>
         </tr>
      </tbody>
    </table>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
  export default {
    name: "CartTable",
    props: ["translation","itemsInCart", "lang", "defaultUrl"],
    data() {
      return {
        items: this.itemsInCart,
      }
    },
    computed: {
      cart() {
        return this.$store.state.cart;
      },
      couponDiscount() {
        return this.$store.state.couponDiscount;
      },
      couponMessage() {
        return this.$store.state.couponMessage;
      },
      couponNo() {
        return this.$store.state.couponNo;
      },
      totalAmount() {
        let total = 0;
        this.items.specification.forEach((specification) => {
          if (this.cart.specification) {
            total += specification.price * this.cart.specification[specification.key];
          }
        });
        return total;
      },
      calculateTotal() {
        let total = 0;
        total = this.totalAmount - this.couponDiscount;
        if (total < 0) {
          total = 0;
        }
        return total;
      },
    },
    methods: {
      add(specificationKey) {
        console.log(specificationKey);
        // 取得當前的數量
        let qty = this.cart.specification[specificationKey];
        let spec = this.items.specification.find((spec) => spec.key === specificationKey);
        qty += 1;
        if (qty > spec.max_qty) {
          swal({
            title: this.translation.messages.more_than_max_qty,
            text: "",
            icon: "warning",
          });
        } else {
          this.$store.dispatch("setCart", { ...this.cart.specification, [specificationKey]: qty });
        }
      },
      minus(specificationKey) {
        let qty = this.cart.specification[specificationKey];
        qty -= 1;
        if (qty <= 0) {
          swal({
            title: this.translation.messages.no_less_than_zero,
            text: "",
            icon: "warning",
          });
        } else {
          this.$store.dispatch("setCart", { ...this.cart.specification, [specificationKey]: qty });
        }
      },
      remove(specificationKey) {
        let tempCart = { ...this.cart.specification };
        delete tempCart[specificationKey];
        this.$store.dispatch("setCart", tempCart);
        this.items.specification = this.items.specification.filter(
          (spec) => spec.key !== specificationKey
        );
        if (this.items.specification.length === 0) {
          swal({
            title: this.translation.empty_cart,
            text: this.translation.select_product,
            icon: "warning",
          }).then(() => {
            window.location.href = this.defaultUrl;
          });
        }
      },
      checkCoupon(e) {
        const value = e.target.value;
        if (value.length >= 5) {
          this.$store.dispatch("setCouponDiscount", value);
        } else {
          this.$store.dispatch("setCouponDiscount", "");
        }
      },
    },
    watch: {
      cart() {
        axios.post("/carts/get_items").then(({ data }) => {
          if (data.status === "success") {
            this.items = data.items_in_cart;
          }
        });
      },
    },
  }
</script>