<template>
  <li v-show="qty > 0">
    <a :href="`/${lang}/carts/orders`" class="cart_icon">
      <i class="icon ion-md-cart">
        <span class="badge">{{ qty }}</span>
      </i>
    </a>
  </li>
</template>

<script>
export default {
  name: 'Minicart',
  props: ["lang"],
  computed: {
    qty() {
      if (this.$store.state.cart?.specification) {
        return Object.keys(this.$store.state.cart.specification).length;
      } else {
        return 0;
      }
    }
  }
}
</script>